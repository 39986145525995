<template>
  <div class="event-list">
    <EventCard v-for="(event, index) in events" :key="index" :event="event" />
  </div>
</template>

<script>
import EventCard from '@/components/EventCard.vue'
import ApiService from '@/services/ApiService.js'

export default {
  name: 'EventList',
  components: {
    EventCard,
  },
  data() {
    return {
      events: null
    }
  },
  created() {
    ApiService.getFacts()
      .then((response) => {
        this.events = response.data.data;
      })
      .catch((error) => {
        console.log(error)
      });
  }
}
</script>
