<template>
  <div v-if="event" class="event-detail">
    <span>@ Fact {{ key }} </span>
    <h4>{{ event.fact }}</h4>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService.js'

export default {
  props: ['id'],
  data() {
    return {
      event: null,
    }
  },
  created() {
    ApiService.getFacts()
      .then((response) => {
        console.log(response.data.data);
        this.event = response.data.data[this.id];
      })
      .catch((error) => {
        console.log(error)
      });
  }
}
</script>

<style>
.event-detail {
  width: 500px;
  margin: 0 auto;
}
</style>