<template>
  <div class="event-card">
    <router-link class="event-link" :to="{ name: 'EventDetails', params: { id: 1 }}">
      <span>@ Fact {{ key }} </span>
        <h4>{{ event.fact }}</h4>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event: Object
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-card {
  padding: 20px;
  width: 400px;
  cursor: pointer;
  border: 1px solid #39495c;
  border-radius: 3px;
  margin: 0 auto 15px;
}

.event-link {
  color: #39495c;
  text-decoration: none;
}
</style>
