import axios from 'axios'

const apiClient = axios.create({
    baseURL: 'https://catfact.ninja',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

export default {
    getFacts() {
        return apiClient.get('/facts?limit=10')
    },
    getFact(id) {
        return apiClient.get(`/${id}`)
    }
}